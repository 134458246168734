<template>
  <b-overlay
    :show="show"
    rounded="sm"
  >
    <b-card title="Increase VOD/Clip Views">
      <div>
        <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >

          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in filteredArray"
            :id="item.id"
            :key="item.id"
            ref="row"
          >

            <!-- Item Name -->
            <b-col md="4">
              <b-form-group
                novalidate
                class="needs-validation"
                label="Link"
                label-for="link"
                @submit.prevent
              >
                <b-form-input
                  id="link"
                  v-model="item.Link"
                  type="url"
                  placeholder="https://www.twitch.tv/videos/512512512"
                  :state="item.Link.includes('twitch.tv')"
                />
              </b-form-group>
            </b-col>
            <!--          &lt;!&ndash; Cost &ndash;&gt;-->
            <!--          <b-col md="2">-->
            <!--            <b-form-group-->
            <!--              label="Cost"-->
            <!--              label-for="cost"-->
            <!--            >-->
            <!--              <b-form-input-->
            <!--                id="cost"-->
            <!--                type="number"-->
            <!--                placeholder="32"-->
            <!--              />-->
            <!--            </b-form-group>-->
            <!--          </b-col>-->

            <!-- Quantity -->
            <b-col md="1">
              <b-form-group
                label="Quantity of Views"
                label-for="quantity"
              >
                <b-form-input
                  id="quantity"
                  v-model.number="item.valueslider"
                  type="number"
                />
              </b-form-group>
            </b-col>

            <!-- Profession -->
            <!-- Profession -->
            <b-col md="3">
              <b-form-group
                label="Quantity of Views"
                label-for="quantity"
              >
                <div class="mt-2">
                  <vue-slider
                    v-model="item.valueslider"
                    :min="0"
                    :max="max"
                    :direction="direction"
                    :drag-on-click="true"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col
              md="2"
            />

            <!-- Remove Button -->
            <b-col
              lg="2"
              md="3"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>Delete</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>

        </b-form>
      </div>
      <b-row>
        <b-col
          md="9"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="repeateAgain"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Add New</span>
          </b-button>
        </b-col>
        <b-col
          md="2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="test"
          >
            <feather-icon
              icon="PlayIcon"
              class="mr-25"
            />
            <span>Increase Views</span>
          </b-button>
        </b-col>
      </b-row>
      <!--    <pre>-->
      <!--    {{ items }}-->

      <!--    </pre>-->
    </b-card>
    <template #overlay>
      <div class="text-center">
        <b-icon
          icon="cash"
          font-scale="3"
          animation="pulse"
        />
        <p id="cancel-label">
          Please buy a license
        </p>
      </div></template>
  </b-overlay>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BOverlay, BCard, BIconCash, BIcon,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import VueSlider from 'vue-slider-component'
import store from '@/store/index'
import firebase from 'firebase'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BIconCash,
    BIcon,
    BOverlay,
    VueSlider,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      twitchname: null,
      show: false,
      selected: ['B'],
      items: [{
        value: ['2'],
        valueslider: 15,
        id: 1,
        options: [
          { text: 'VOD', value: '1' },
          { text: 'Clip', value: '2' },
        ],
        prevHeight: 0,
        Link: '',
        views: null,
      },
      {
        value: ['2'],
        id: 2,
        valueslider: 15,
        options: [
          { text: 'VOD', value: '1' },
          { text: 'Clip', value: '2' },
        ],
        prevHeight: 0,
        Link: '',
        views: null,
      }],
      state: false,
      nextTodoId: 3,
      max: 300,
      value1: 30,
      value5: 50,
      dir: 'ltr',
      options: [
        { text: 'VOD', value: '1' },
        { text: 'Clip', value: '2' },
      ],
    }
  },
  // watch: {
  //   item() {
  //     console.log(this.items[1])
  //   },
  // },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
    filteredArray() {
      return this.items.filter(item => {
        // Many more calculations
        if (item.id !== 4) {
          /* eslint-disable no-param-reassign */
          // console.log(item.value.length === 1)
          item.state = item.value.length === 1
          return true
        }
        return false
      })
    },
  },
  mounted() {
    this.initTrHeight()
    // ABFRAGE MAX INCREASE
    const userData = getUserData()
    const sx = this.$firebase.firestore()
    sx.collection('User')
      .doc(userData.id)
      .get()
      .then(snap => {
        // console.log(snap.data().Package)
        if (snap.data().Package === 'None') {
          this.show = true
        }
        this.twitchname = snap.data().twitchname
        try {
          this.max = snap.data().License.vodAmount
          if (this.max === undefined) this.max = 300
        } catch (e) {
          console.log('Catched')
          this.max = 300
          console.log('NothingDisclosedInLicense -> 300')
        }
        console.log(this.max)
        console.log(snap.data().License.vodAmount)
        console.log('SETT')
      })
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    // check(item) {
    //   const Link1 = item.Link
    //   if (Link1.includes('twitch.tv')) {
    //     return true
    //   }
    //   return false
    // },
    test() {
      // const sx = this.$firebase.firestore()
      // sx.collection('VOD')
      //   .doc('VODs') // USER ID
      // // .collection('All')
      // // .doc('Allgemein')
      //   .get()
      //   .then(doc => {
      //     if (doc.exists) {
      //       console.log(doc.data().VOD)
      //       console.log('Document data:', doc.data(
      //     } else {
      //       // doc.data() will be undefined in this case
      //       console.log('No such document!')
      //     }
      //   })
      const db = this.$firebase.firestore()
      const userData = getUserData()
      this.items.forEach(element => {
        let vod = true
        if (element.Link.includes('clip')) vod = false
        // if (element.Link !== '') {
        if (element.Link.includes('twitch.tv')) {
          db.collection('VOD')
            .add({
              Link: element.Link,
              UIDOFUser: userData.id,
              VOD: vod,
              Views: element.valueslider,
              emailofuser: userData.email,
              twitchname: this.twitchname,
              Increased: false,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
              // console.log('Document successfully updated!')
              this.$swal({
                title: 'Started Job!',
                text: 'Views are added in the next 24h!',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              // The document probably doesn't exist.
              console.error('Error updating document: ', error)
            })
        }
      })
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
        options: this.options,
        value: this.items[0].value,
        valueslider: this.items[0].valueslider,
        Link: '',
        views: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
